import React from 'react';
import '../styles/ApprovalChainLink.scss';
import { Divider } from '@mui/material';
import { CheckCircle, PendingActions, Person } from '@mui/icons-material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import moment from 'moment';

interface ApproverProps {
  type?: string;
  email?: string;
}

interface ApprovalChainLinkProps extends ApproverProps {
  orApprover?: ApproverProps;
  approvers?: ApproverProps[];
  approversByRole?: any;
  groupInfo?: any
}

const ApprovalChainLink: React.FC<ApprovalChainLinkProps> = ({
  approvers,
  type,
  approversByRole,
  groupInfo
}) => {
  const renderIcon = (type: string) => {

    if (type?.toLowerCase() === 'flow_approved')
      return <CheckCircle style={{ color: '#50AF78' }} />;
    if (type?.toLowerCase() === 'flow_pending')
      return <CircleOutlinedIcon style={{ color: '#FF9742' }} />;
    if (type?.toLowerCase() === 'flow_no_action_needed')
      return <RemoveOutlinedIcon style={{ color: '#E7E7F9' }} />;
    if (type?.toLowerCase() === 'flow_no_action_taken')
      return <RemoveOutlinedIcon style={{ color: '#E7E7F9' }} />;
    if (type?.toLowerCase() === 'flow_skipped')
      return <RemoveOutlinedIcon style={{ color: '#e7e7f9' }} />;
    if (type?.toLowerCase() === 'flow_rejected')
      return <CancelIcon style={{ color: '#FF543E' }} />;
    return <CircleOutlinedIcon style={{ color: '#E7E7F9' }} />;
  };

  const containerBorder = (type?: string) => {
    if (type?.toLowerCase() === 'completed') return 'border-completed';
    if (type?.toLowerCase() === 'active') return 'border-active';
    return 'border-in-queue';
  };

  return <React.Fragment >
    <div className={`approval-chain-link ${containerBorder(groupInfo.containerClass)}`}>
      {groupInfo.approversByRole.map((roleGroup: any, roleIndex: number) => {
        return (
          <div className="approval-sequence" key={roleIndex}>
            {roleIndex !== 0 && <Divider className="my-3"> AND </Divider>}
            <div className='d-flex'>

              <p>{roleGroup.role} </p>
              {roleGroup.approvers?.length > 1 && <ul className='pl-4'><li><FormattedPlural
                one={"1 is required"}
                other={`${roleGroup.responseCount} are required`}
                value={roleGroup.responseCount}
              /></li></ul>}
            </div>
            {roleGroup.approvers &&
              roleGroup.approvers.map((approver: any, index: number) => {
                const hasPersonId = approver.personId
                const status = approver.status;
                const user = approver.user;
                return (
                <React.Fragment key={index}>

                  <div className="approval-row" style={{ marginTop: index ? 6 : 0 }} >
                    <div className="approval-inner">
                      <div className="icon-wrap">{hasPersonId && renderIcon(approver.iconClass)}</div>
                      <div className="lv-set">
                        {hasPersonId ?(<>{user?.firstName && <div className="value" style={{fontWeight: 600}}>{user?.firstName} {user?.lastName}</div> }
                        {user?.email && (
                          <div className="value">{user?.email}</div>
                        )}
                        <div className="value">{status}</div></>)
                        : <div className='value'>No approvers are configured</div>}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )})}
          </div>
        )
      })}
    </div>
  </React.Fragment>
};

export default ApprovalChainLink;
