import moment from "moment";

export const formatUserName = (user: any) => {
    if (!user) return '';
    if (user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName}`;
    }
    if (user.email) {
        return user.email;
    }
    return '';
};

export const formatDateTime = (dateTime: string) => {
    if (!dateTime) return '';
    return moment(dateTime).format('DD MMM YYYY h:mm A');
};