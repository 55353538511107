import * as React from 'react';
import '../styles/request-details.scss';
import {Grid, Skeleton} from '@mui/material';
import moment from "moment/moment";
import xss from 'xss';
import { Box } from '@mui/system';
import { customFormatDate } from 'util/utilityMethods';

interface RequestDetails {
    workRequestId?: any
    requestId?: any
    instrumentId?: any
    assetId?: any
    assetId_data?: any
    c_additionallocationdetails?: any
    c_additionalrequestoremail?: any
    c_alreadyserialnumber?: any
    c_alternativeemailone?: any
    c_arcassetdescription?: any
    c_arcassetid?: any
    c_arcassetstatus?: any
    c_arcbackuplabmanager?: any
    c_arcbudget?: any
    c_arcbuilding?: any
    c_arcbuilding_data?: any
    c_arcbuildingnewchoice?: any
    c_arccalibrationseveryxmonths?: any
    c_arccapexvalue?: any
    c_arcclientcostcenter?: any
    c_arcclientcostcenter_data?: any
    c_arcclientcriticalitylevel?: any
    c_arcclientcriticalitylevel_data?: any
    c_arcclientfinancialid?: any
    c_arccommit?: any
    c_arcdepartment?: any
    c_arcdepartment_data?: any
    c_arcendofwarrantydate?: any
    c_arcfinancialapprover?: any
    c_arcfinancialapprover_data?: any
    c_arcflag?: any
    c_arcfloorlevel?: any
    c_arcgxpstatus?: any
    c_arcgxpstatus_data?: any
    c_arcinstalldate?: any
    c_arcinstrumentowner?: any
    c_arcinstrumentowner_data?: any
    c_arclabmanager?: any
    c_arclabmanager_data?: any
    c_arclocalserviceline?: any
    c_arcmanufacturedate?: any
    c_arcmanufacturer?: any
    c_arcmanufacturer_data?: any
    c_arcnewserviceprovider?: any
    c_arcnewsystem?: any
    c_arcnextcalibrationdate?: any
    c_arcnextcertificationdate?: any
    c_arcnextqualificationdate?: any
    c_arcnextservicedate?: any
    c_arcnumberofcalibrations?: any
    c_arcnumberofcalibrations_data?: any
    c_arcnumberofpms?: any
    c_arcnumberofpms_data?: any
    c_arcnumberofqualifications?: any
    c_arcnumberofqualifications_data?: any
    c_arcnumberofrepairs?: any
    c_arcnumberofrepairs_data?: any
    c_arcpmseveryxmonths?: any
    c_arcpurchasecost?: any
    c_arcqualificationseveryxmonths?: any
    c_arcqualityapprover?: any
    c_arcqualityapprover_data?: any
    c_arcreplacementcost?: any
    c_arcreplacementdate?: any
    c_arcroomspace?: any
    c_arcserialnumber?: any
    c_arcsystem?: any
    c_assetadded?: any
    c_assetclass?: any
    c_assetdeconrisk?: any
    c_assetdescription?: any
    c_assetlookup?: any
    c_assettype?: any
    c_availablestatus?: any
    c_backuplabmanager?: any
    c_biologicalcontaminationrisk?: any
    c_budget?: any
    c_building?: any
    c_buildingname?: any
    c_calibrationseveryxmonths?: any
    c_chemicalcontaminationrisk?: any
    c_clientcostcenter?: any
    c_clientcriticalitylevel?: any
    c_clientfinanceid?: any
    c_compid?: any
    c_contaminationassessment?: any
    c_contaminationrisk?: any
    c_contaminationriskb?: any
    c_contaminationriskc?: any
    c_controlmeasures?: any
    c_deconmethod?: any
    c_department?: any
    c_department_data?: any
    c_detaileddescription?: any
    c_documenta?: any
    c_documentb?: any
    c_documentc?: any
    c_email?: any
    c_floorlevel?: any
    c_freearcmanufacturer?: any
    c_freearcmodel?: any
    c_gxpstatus?: any
    c_gxpstatus_data?: any
    c_imagefield?: any
    c_instrumentowner?: any
    c_instrumentworkingstatus?: any
    c_istherearisktouserpatientorcriticalprocess?: any
    c_kpienabled?: any
    c_labmanager?: any
    c_locationadditionaldetails?: any
    c_manufacturer?: any
    c_model?: any
    c_modelnumber?: any
    c_neededbydate?: any
    c_nextcalibrationdate?: any
    c_nextcertificationdate?: any
    c_nextpmdate?: any
    c_nextqualificationdate?: any
    c_numberofcalibrations_data?: any
    c_numberofpms_data?: any
    c_numberofqualifications_data?: any
    c_numberofrepairs_data?: any
    c_pmseveryxmonths?: any
    c_problemtype?: any
    c_qualificationseveryxmonths?: any
    c_radioactivitycontaminationrisk?: any
    c_relatedwo?: any
    c_requestedon?: any
    c_requesteremail?: any
    c_requesterphone?: any
    c_requesttype?: any
    c_residualcontamination?: any
    c_roomspace?: any
    c_serialnumber?: any
    c_serviceprovider?: any
    c_site?: any
    c_system?: any
    c_system_data?: any
    c_timedateofapproval?: any
    c_triage?: any
    c_workordertype?: any
    c_workordertype_data?: any
    c_workphone?: any
    c_workservicegroup?: any
    description?: any
    details?: any
    formId?: any
    comments?: any
    image?: any
    number?: any
    rejectionReason?: any
    requestedBy?: any
    requestedBy_data?: any
    site?: any
    status?: any
    createdBySource?: any
    createdBySource_data?: any
    createdBy?: any
    createdOnSource?: any
    createdOn?: any
    updatedBySource?: any
    updatedBySource_data?: any
    updatedBy?: any
    updatedOnSource?: any
    updatedSyncDateSource?: any
    updatedOn?: any
    isDeleted?: any
    enabled?: any
    workrequest_timeline_status?: string
    wr_system_name?: string
    wr_cc_name?: string
    wo_cc_name?: string
    c_scheduleddateandtime?: any
    primaryassignment_fullname?: any
    primaryassignment_company?: any
    c_serviceprovider_data?: any
    c_arcbackuplabmanager_data?: any
    c_clientcriticalitylevel_data?: any
    c_currentserviceprovider?: any
    c_wr_arcclientcostcenter_name?: any
    ins_wo_cc_name?: any
    ins_wr_cc_name?: any
    wr_arc_system_name?: any
    wo_system_name?: any
    c_instrumentowner_data?: any
    c_backupinstrumentowner_data?: any
    c_labmanager_data?: any
    c_backuplabmanager_data?: any
    request_td_clientcriticalitylevel?: any
    workOrderIdSource?: any
    wo_number?: any
}

interface Props {
    requestData: RequestDetails;
    loading?: boolean
}

const CustomData = (val: React.ReactNode, val2?: React.ReactNode) => {
    return <div><div className="d-flex align-items-center">
        <Box
            sx={{
                padding: "2px 8px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
                bgcolor: '#FBEEEE',
                marginBottom: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                whiteSpace: "nowrap",
                textDecoration: val ? "line-through" : "",
                minHeight: "1rem"
            }}
        >{val ? val : <span className='empty-value'>Empty</span>}
        </Box>
    </div>
        {
            <div className="d-flex align-items-center">

                <Box
                    sx={{
                        padding: "2px 8px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 1,
                        bgcolor: '#E6F4EC',
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "block",
                        whiteSpace: "nowrap",
                         minHeight: "1rem"

                    }}
                >{val2 ? val2 : <span className='empty-value'>Empty</span>}
                </Box>
            </div>
        }
    </div>
}

const RequestDetailInfo: React.FC<Props> = ({
    requestData,
    loading
}) => {

    const renderValues = (key:string, apiData = '') => {
      return modifiedInfo[key] ? CustomData(modifiedInfo[key].prev, modifiedInfo[key].next) : apiData
    }

    const modifiedInfo = (requestData as { previousinstrumentdata: {[key:string]: {prev:any, next:any}} })?.previousinstrumentdata || {}

    const isItHasWorkOrder = requestData?.workOrderIdSource || requestData?.wo_number;
    return (
        <div className='request-details-info'>
            <div className="wrapper">
                <main className="content">

                    <section className="main">
                        <article>
                            <section className="section">
                                {loading ? <><Skeleton />
                                    <Skeleton width="60%" />
                                    <Skeleton variant="rectangular" width="60%"   ></Skeleton>
                                </>
                                : <div className={'section-container'}>
                                        <div className={'section-1'}>
                                        <div className={'section-item'} >
                                            <h2 className="sectionTitle">Request Details</h2>
                                            <div className="detailItem">
                                                <span className="detailLabel">Work Type</span>
                                                <span className="detailValue">{requestData?.c_requesttype}</span>
                                            </div>
                                            <div className="detailItem">
                                                <span className="detailLabel">Brief Description</span>
                                                <span className="detailValue">{ requestData?.description || '' }</span>
                                            </div>
                                            <div className="detailItem">
                                            <span className="detailLabel">Work Order Details</span>
                                            <div className="work-order-details detailValue">
                                                <span className="detailValue" dangerouslySetInnerHTML={{__html: xss(requestData?.details || '')}} />
                                            </div>
                                        </div>
                                        </div>
                                        <div className={'section-item'} >
                                            <h2 className="sectionTitle">Asset Scope and Ownership</h2>
                                            <div className="detailItem">
                                                <span className="detailLabel">Requested By</span>
                                                <span className="detailValue">{requestData?.requestedBy_data?.title}</span>
                                            </div>
                                            <div className="detailItem">
                                                <span className="detailLabel">Requested On</span>
                                                <span className="detailValue">{requestData?.createdOnSource? customFormatDate(requestData?.createdOnSource, true) : ''}
                                            </span>
                                            </div>
                                            <div className="detailItem">
                                                <span className="detailLabel">Additional Requestor E-Mail</span>
                                                <span className="detailValue">{requestData?.c_additionalrequestoremail}</span>
                                            </div>
                                        </div>
                                        <div className={'section-item'} >
                                            <h2 className="sectionTitle">Asset Location</h2>
                                            <div className="detailItem">
                                                <span className="detailLabel">Building</span>
                                                <span className="detailValue">{ renderValues("building",  requestData?.c_arcbuildingnewchoice || requestData?.c_arcbuilding || requestData?.c_buildingname || '')}</span>
                                            </div>
                                            <div className="detailItem">
                                                <span className="detailLabel">Floor Level</span>
                                                <span className="detailValue">{renderValues("floorLevel", requestData?.c_arcfloorlevel || requestData?.c_floorlevel || '')}</span>
                                            </div>
                                            <div className="detailItem">
                                                <span className="detailLabel">Room Space</span>
                                                <span className="detailValue">{renderValues("roomSpace", requestData?.c_arcroomspace || requestData?.c_roomspace || '')}</span>
                                            </div>
                                            <div className="detailItem">
                                                <span className="detailLabel">Department</span>
                                                <span className="detailValue">{renderValues("department", requestData?.c_arcdepartment_data?.title || requestData?.c_department_data?.title || '')}</span>
                                            </div>
                                            <div className="detailItem">
                                                <span className="detailLabel">GxP Status</span>
                                                <span className="detailValue">{renderValues("gxpStatus", requestData?.c_arcgxpstatus_data?.title || requestData?.c_gxpstatus_data?.title || '')}</span>
                                            </div>
                                            <div className="detailItem">
                                                <span className="detailLabel">Business Criticality</span>
                                                <span className="detailValue">{  renderValues("businessCriticality", requestData.request_td_clientcriticalitylevel)
                                                //  requestData?.c_arcclientcriticalitylevel_data?.title || requestData?.c_clientcriticalitylevel_data?.title || ''
                                                }
                                                 </span>
                                            </div>
                                        </div>
                                        <div className={'section-item'} >
                                            <h2 className="sectionTitle">Nameplate Information</h2>
                                            <div className="detailItem">
                                                <span className="detailLabel">Manufacturer</span>
                                                <span className="detailValue">{renderValues("manufacturer",requestData?.c_arcmanufacturer_data?.title || requestData?.c_freearcmanufacturer || requestData?.c_manufacturer || '')}</span>
                                            </div>
                                            <div className="detailItem">
                                                <span className="detailLabel">Model Number</span>
                                                <span className="detailValue">{renderValues("modelNumber",requestData?.c_freearcmodel || requestData?.c_modelnumber)}</span>
                                            </div>
                                            <div className="detailItem">
                                                <span className="detailLabel">Asset Class</span>
                                                <span className="detailValue">{renderValues("assetClass",requestData?.c_assetclass)}</span>
                                            </div>
                                            <div className="detailItem">
                                                <span className="detailLabel">Asset Type</span>
                                                <span className="detailValue">{renderValues("assetType",requestData?.c_assettype)}</span>
                                            </div>
                                            <div className="detailItem">
                                                <span className="detailLabel">Serial Number</span>
                                                <span className="detailValue">{renderValues("serialNumber",requestData?.c_arcserialnumber || requestData?.c_serialnumber )}</span>
                                            </div>
                                            <div className="detailItem">
                                                <span className="detailLabel">Service Provider</span>
                                                <span className="detailValue">{renderValues("serviceProvider",requestData?.c_arcnewserviceprovider || requestData?.c_serviceprovider_data?.title  || requestData?.c_serviceprovider)}</span>
                                            </div>
                                        </div>
                                        <div className={'section-item'} >
                                            <h2 className="sectionTitle">Financial</h2>
                                            <div className="detailItem">
                                                <span className="detailLabel">Client Cost Center</span>
                                                <span className="detailValue">{renderValues("costCenter", requestData?.c_wr_arcclientcostcenter_name || requestData?.wo_cc_name || requestData?.wr_cc_name  || requestData?.ins_wo_cc_name  || requestData?.ins_wr_cc_name)}</span>
                                            </div>
                                        </div>
                                   </div>
                                     <div className={'section-2'}>
                                    <div className={'section-item'} >
                                        <h2 className="sectionTitle">Asset Information</h2>
                                        <div className="detailItem">
                                            <span className="detailLabel">Asset ID</span>
                                            <span className="detailValue">{requestData?.c_compid || ''}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">Asset Description</span>
                                            <span className="detailValue">{renderValues("instrumentDescription", requestData?.c_arcassetdescription || requestData?.c_assetdescription)}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">System</span>
                                            <span className="detailValue">{renderValues("system", requestData?.c_arcnewsystem || requestData?.wr_arc_system_name || requestData?.wo_system_name || requestData?.wr_system_name || '')}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">Instrument Owner</span>
                                            <span className="detailValue">{renderValues("instrumentOwner", requestData?.c_arcinstrumentowner_data?.title || requestData?.c_instrumentowner_data?.title)}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">Backup Instrument Owner</span>
                                            <span className="detailValue">{requestData?.c_backupinstrumentowner_data?.title}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">Lab Manager</span>
                                            <span className="detailValue">{renderValues("labManager", requestData?.c_arclabmanager_data?.title || requestData?.c_labmanager_data?.title)}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">Backup Lab Manager</span>
                                            <span className="detailValue">{renderValues("backupLabManager", requestData?.c_arcbackuplabmanager_data?.title || requestData?.c_backuplabmanager_data?.title)}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">Financial Approver</span>
                                            <span className="detailValue">{renderValues("financialApprover", requestData?.c_arcfinancialapprover_data?.title || '')}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">Quality Approver</span>
                                            <span className="detailValue">{renderValues("qualityApprover", requestData?.c_arcqualityapprover_data?.title || '')}</span>
                                        </div>
                                    </div>
                                    <div className={'section-item'} >
                                        <h2 className="sectionTitle">Annual Entitlements</h2>
                                        <div className="detailItem">
                                            <span className="detailLabel">PMs</span>
                                            <span className="detailValue">{renderValues("pms", requestData?.c_arcnumberofpms_data?.title || requestData?.c_numberofpms_data?.title)}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">Qualifications</span>
                                            <span className="detailValue">{renderValues("qualifications", requestData?.c_arcnumberofqualifications_data?.title || requestData?.c_numberofqualifications_data?.title)}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">Calibrations</span>
                                            <span className="detailValue">{renderValues("calibrations", requestData?.c_arcnumberofcalibrations_data?.title || requestData?.c_numberofcalibrations_data?.title)}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">Repairs</span>
                                            <span className="detailValue">{ renderValues("repairs", requestData?.c_arcnumberofrepairs_data?.title || requestData?.c_numberofrepairs_data?.title)}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">Next Service Date</span>
                                            <span className="detailValue">{ requestData?.c_arcnextservicedate? customFormatDate(requestData?.c_arcnextservicedate, true) : '' }</span>
                                        </div>
                                    </div>
                                    { isItHasWorkOrder && <div className={'section-item'} >
                                        <h2 className="sectionTitle">Scheduling</h2>
                                        <div className="detailItem">
                                            <span className="detailLabel">Date</span>
                                            <span className="detailValue">{requestData?.c_scheduleddateandtime? moment(requestData?.c_scheduleddateandtime).format('l') : 'N/A'}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">Time</span>
                                            <span className="detailValue">{requestData?.c_scheduleddateandtime? moment(requestData?.c_scheduleddateandtime).format('LT') : 'N/A'}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">Company</span>
                                            <span className="detailValue">{requestData?.primaryassignment_company}</span>
                                        </div>
                                        <div className="detailItem">
                                            <span className="detailLabel">Technician</span>
                                            <span className="detailValue">{requestData?.primaryassignment_fullname}</span>
                                        </div>
                                    </div>}
                                     </div>
                                </div>
                                }
                            </section>
                        </article>
                    </section>
                </main>
            </div>
        </div>

    );
};

export default RequestDetailInfo;
