import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Backdrop, CircularProgress, styled } from '@mui/material';
import HistoryItem from './historyItem';
import service from 'util/apiService';
import { ApprovalAuditHistory } from '../types';
import moment from 'moment';
import theme from "./theme";
import { customFormatDate } from 'util/utilityMethods';

interface ApprovalHistoryProps {
    approvals?: number;
    totalApprovals?: number;
    flowId: string;
    baseUrl: string;
}

const ApprovalHistoryContainer = styled(Box)(({ theme }) => ({
    backgroundColor: 'var(--Backgrounds-Surface, #FFF)',
    display: 'flex',
    maxWidth: '540px',
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'start',
    fontFamily: 'Calibre-R, -apple-system, Roboto, Helvetica, sans-serif',
    fontSize: '14px',
}));

const HistoryWrap = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'start',
}));

const CustomBackdrop = styled(Backdrop)({
    zIndex: 1000,
    color: '#fff',
});

const CustomProgress = styled(CircularProgress)({
    color: 'var(--Primary-Text, #0052CC)',
});

const ApprovalsHistory: React.FC<ApprovalHistoryProps> = ({ approvals, flowId, baseUrl }) => {
    const [historyItems, setHistoryItems] = useState<ApprovalAuditHistory[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        service
            .get(`${baseUrl}/approval-audit-history/${flowId}`)
            .then((response) => {
                // Sort items by timestamp
                const sortedItems = (response.data || []).sort((a: ApprovalAuditHistory, b: ApprovalAuditHistory) => {
                    return new Date(b.events?.timestamp || 0).getTime() - new Date(a.events?.timestamp ||0).getTime();
                });
                const groupedItems = sortedItems.reduce((acc: ApprovalAuditHistory[], curr: ApprovalAuditHistory) => {
                    if (curr.events?.event === 'REMINDER_SENT' && curr.events?.correlationId) {
                        const existingItem = acc.find(item =>
                            item.events?.event === 'REMINDER_SENT' &&
                            item.events?.correlationId === curr.events.correlationId
                        );


                        if (existingItem) {
                            if (!existingItem.events.groupedReminders) {
                                existingItem.events.groupedReminders = [existingItem.events.reminderSentTo];
                            }
                            existingItem.events.groupedReminders.push(curr.events.reminderSentTo);
                            return acc;
                        }
                    }
                    return [...acc, curr];
                }, []);

                setHistoryItems(groupedItems);
                //setHistoryItems(sortedItems);
            })
            .catch((error) => {
                console.error('Error fetching approval history', error);
            })
            .finally(() => setLoading(false));
    }, [baseUrl, flowId]);

    const renderHistoryItem = (item: ApprovalAuditHistory, index: number) => {
        const info = item.events || {};
        const date = info.timestamp ? customFormatDate(info.timestamp, true) : '';

        return <HistoryItem
            key={index}
            status={info.event || ''}
            date={date}
            events={info}
        />;
    };

    return (
        <ThemeProvider theme={theme}>
        <ApprovalHistoryContainer>
            <HistoryWrap>
                {historyItems.length !== 0 && historyItems.map((item, index) => renderHistoryItem(item, index))}
            </HistoryWrap>
            <CustomBackdrop open={loading}>
                <CustomProgress />
            </CustomBackdrop>
        </ApprovalHistoryContainer>
        </ThemeProvider>
    );
};

export default ApprovalsHistory;