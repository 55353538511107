
/* eslint-disable */
import axios from 'axios';

const http = axios.create({
  headers: {
    'Content-type': 'application/json'
  }
});

const upload = (saveUrl, details, onUploadProgress) => {
  const formData = new FormData();
  if (details.projectId) {
    formData.append('projectId', details.projectId);
    //formData.append('pjmAppCode', details.pjmAppCode);
  }

  if (details?.documentDetailHdrId) {
    formData.append('documentDetailHdrId', details.documentDetailHdrId);
  }
  if (details?.folderId) {
    formData.append('filefolderId', details.folderId);
  }

  formData.append('files', details.file);
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-authorization': sessionStorage.getItem('flow-token'),
      'x-locale': localStorage.getItem('locale') || 'en_US',
      AzureToken: sessionStorage.getItem('accessToken')
    },
    onUploadProgress
  };
  return http.post(saveUrl, formData, options);
};

const downloadFile = (downloadUrl, fileId, others = {}) => {
  const options = {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      'x-authorization': sessionStorage.getItem('flow-token'),
      'x-locale': localStorage.getItem('locale') || 'en_US',
      AzureToken: sessionStorage.getItem('accessToken')
    }
  };
  const queryString = fileId ? `?fileIds=${fileId}` : '';
  return fetch(`${downloadUrl}${queryString}`, options);
};

const getDocumentId = (url, body) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'x-authorization': sessionStorage.getItem('flow-token'),
      'x-locale': localStorage.getItem('locale') || 'en_US',
      AzureToken: sessionStorage.getItem('accessToken')
    }
  };
  return  http.post(url, body, options);
};

async function fetchFileBlobUrls(downloadUrl, fileDetailsList) {
  if (!fileDetailsList || fileDetailsList.length <= 0) return [];

  const options = {
    responseType: 'blob',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-authorization': sessionStorage.getItem('pjm-token'),
      'x-locale': localStorage.getItem('locale') || 'en_US',
      AzureToken: sessionStorage.getItem('accessToken')
    }
  };

  try {
    const fileBlobUrlObjects = await Promise.all(
      fileDetailsList.map(async ({ fileId }) => {
        const newFileBlob = await http.get(
          `${downloadUrl}/${fileId}/download`,
          options
        );

        const url = window.URL.createObjectURL(newFileBlob.data);
        return {
          fileId,
          url
        };
      })
    );
    return fileBlobUrlObjects;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function fetchFileUrl(downloadUrl, fileId) {

  const options = {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      'x-authorization': sessionStorage.getItem('flow-token'),
      'x-locale': localStorage.getItem('locale') || 'en_US',
      AzureToken: sessionStorage.getItem('accessToken')
    }
  };
  const queryString = fileId ? `?fileIds=${fileId}` : '';

  try {
    const newFileBlob = await http.get(
      `${downloadUrl}${queryString}`,
      options
    );

    const url = window.URL.createObjectURL(newFileBlob.data);

    return url;
  } catch (error) {
    console.error(error);
    return [];
  }
}

const saveFileDetail = (saveFileUrl, payload) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-authorization': sessionStorage.getItem('pjm-token'),
      'x-locale': localStorage.getItem('locale') || 'en_US',
      AzureToken: sessionStorage.getItem('accessToken')
    }
  };
  return http.put(saveFileUrl, payload, options);
  
};

const FileService = {
  upload,
  downloadFile,
  fetchFileBlobUrls,
  fetchFileUrl,
  getDocumentId,
  saveFileDetail
};

export default FileService;
