import { useSelector } from 'react-redux';
import AppLocale from '../lngProvider';
import moment from 'moment';

/* eslint-disable */
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const meterToFoot = 3.28084;
export const dateFormatter = (date, format) => {
  // const dateObj = new Date(date);
  const dateObj = new Date(date).toISOString().slice(0, 10);
  const dateSplit = dateObj.split('-');
  const dateText = dateSplit[2] < 10 ? `0${dateSplit[1]}` : dateSplit[2];
  const month = (new Date(date).getMonth() + 1) < 10 ? `0${new Date(date).getMonth() + 1}` : new Date(date).getMonth() + 1;
  const year = dateSplit[0];
  switch (true) {
    case format === 'DD/MM/YYYY':
      return `${dateText}/${month}/${year}`;
    case format === 'MM/DD/YYYY':
      return `${month}/${dateText}/${year}`;
    case format === 'dateString':
      return `${months[new Date(date).getMonth()]} ${dateText}, ${year}`;
    default:
      break;
  }
};
export const roundToTwoDecimals = (value) => Number(`${Math.round(`${value}e2`)}e-2`);
export const unitConversion = (value, selectedUnit) => {
  if (value > 0) {
    return selectedUnit === 'SQ MT' ? roundToTwoDecimals(value / meterToFoot) : roundToTwoDecimals(value * meterToFoot);
  }
  return value;
};
export const currencyRangeSymbol =(num) =>{
  let isNegative = false
  if (num < 0) {
      isNegative = true
  }
  num = Math.abs(num)
  let formattedNumber;
  if (num >= 1000000000) {
      formattedNumber = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  } else if (num >= 1000000) {
      formattedNumber =  (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else  if (num >= 1000) {
      formattedNumber =  (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else {
      formattedNumber = num;
  }   
  if(isNegative) { formattedNumber = '-' + formattedNumber }
  return formattedNumber;
}

export function interpolate(message, interpolations = {}) {
  return Object.keys(interpolations).reduce(
    (interpolated, key) =>
      interpolated.replace(
        new RegExp(`{\\s*${key}\\s*}`, "g"),
        interpolations[key],
      ),
    message,
  );
}

export const getMessage = ({id, defaultMessage, ...rest}) => {
  let localizedText = AppLocale[localStorage.getItem("locale")]?.messages || {};
 return interpolate(localizedText[id], rest) || defaultMessage || id
}

export  const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const useSiteSelection = () => {
  const { siteInfo } = useSelector((state)=> state["cbre-flow-site-location"]) || {};
  const { selectedLocation = {} } = useSelector((state) => state?.['auth'] || {});
  const { site } = siteInfo || {};    
  const currentSite = site || selectedLocation?.site;
  return currentSite;
}

export const customFormatDate = (dateInput, showTime = true, format) => {
  const parsedDate = moment(dateInput);
  
  if (!parsedDate.isValid()) {
    return 'Invalid date format';
  }
  
  if (format) {
    return parsedDate.format(format);
  }
  
  return parsedDate.format(showTime ? 'DD MMM YYYY h:mm A' : 'DD MMM YYYY');
}