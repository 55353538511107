import * as React from 'react';
import { IconButton, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useApprovalsContext } from 'modules/cbre-approvals-widget';
import { IApprovalTab } from 'modules/cbre-approvals-widget/types';

const options = ['Due Date', 'Requested date', 'Last Activity'];

export default function ApprovalSortByBtn() {
  const { tabValue, config } = useApprovalsContext()!;
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const sortOrder = config.approvalsList.params.order?.toLowerCase() || 'asc'

  const handleSort = () => {
    const newSortOrder: 'asc' | 'desc' = sortOrder === 'asc' ? 'desc' : 'asc';

    // Add sortField based on selected tab and index
    let sortField: string;
    const sortType = 'datetime'; // Default sort type

     switch (tabValue.value) {
      case "myApprovals":
        sortField = "approvalDueDate";
        break;
      case "externalApprovals":
      case "externalApprovalsHistory":
        sortField = "approvalFlow.sourceMetaData.requestedOn"; // Update path to match data structure
        break;
      case "approvalsHistory":
        sortField = "approvalFlow.updatedAt"; // Update path to match data structure
        break;
      default:
        sortField = "approvalDueDate";
    }

    config.approvalsList.setQueryParams((prev) => {
      const updatedParams = { 
        ...prev, 
        order: newSortOrder?.toUpperCase() as any,
        sortBy: sortField,
        sortType: sortType
      };
      return updatedParams;
    });
    
    config.approvalsList.handlePendingChanges().then((config) => {
      config.fetchApprovals()
    });

  };

  React.useEffect(() => {
    if (tabValue.value === "myApprovals") setSelectedIndex(0);
    if (tabValue.value === "externalApprovals" || tabValue.value === "externalApprovalsHistory") setSelectedIndex(1);
    if (tabValue.value === "approvalsHistory") setSelectedIndex(2);
  }, [tabValue.value]);

  const approvalLength  = config.approvalsList.totalRecords || 0;

  const { approvalsCount } = config;

  const renderCount = (value: IApprovalTab["tab"]["value"]) => {
    if (value === "myApprovals") return approvalsCount.myApprovalCount || 0;
    if (value === "externalApprovals" || value === "externalApprovalsHistory") return approvalsCount.externalApprovalCount || 0;
    if (value === "approvalsHistory") return approvalsCount.historyCount || 0;
    return null;
  };


  return (
    <div className='d-flex align-items-center justify-content-between'>
      <Typography> { approvalLength } Approvals </Typography>

      <Typography> Sort by: {options[selectedIndex]}
        <IconButton
          size="small"
          className='sort-by-approval-btn-asc-desc border-none'
          onClick={handleSort}
        >
          {sortOrder === 'asc' ? <ArrowUpwardIcon sx={{width: 18, height: 18}}/> : <ArrowDownwardIcon sx={{width: 18, height: 18}}/>}
        </IconButton>
      </Typography>
    </div>
  );
}