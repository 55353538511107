import React from 'react';
import { ListItem, ListItemText, Typography, Box } from '@mui/material';
import '../styles/ApprovalListItem.scss';
import moment from 'moment';
import { type IApprovalTab, type ApprovalObject, ApprovalTypes } from 'modules/cbre-approvals-widget/types';
import { approvalTypes } from 'modules/cbre-approvals-widget/components/utils';
import { customFormatDate } from 'util/utilityMethods';

interface ApprovalListItemProps {
  approval: ApprovalObject;
  selectedApproval: ApprovalObject | null;
  onClick: () => void;
  style: React.CSSProperties;
  tabValue: IApprovalTab["tab"]
}



const renderDate = (tabValue: IApprovalTab["tab"], approval: ApprovalObject) => {

  if (tabValue.value === "myApprovals") {
    return `Due on ${approval?.approvalDueDate ? 
      customFormatDate(moment(approval?.approvalDueDate).local()) : ""}`;
  }
  
  if (tabValue.value === "externalApprovals" || tabValue.value === "externalApprovalsHistory") {
    const date = approval?.approvalFlow?.sourceMetaData?.requestedOn ?? 
      approval?.approvalFlow?.sourceDetails?.additionalFields.find(
        (field) => field.fieldName === "createdAt"
      )?.value;
    return `Requested on ${date ? customFormatDate(moment(date).local()) : ""}`;
  }
  
  if (tabValue.value === "approvalsHistory") {
    return `${approval?.updatedAt ? 
      customFormatDate(moment(approval?.updatedAt).local()) : ""}`;
  }
}

const renderApprovalStatus = (tabValue: IApprovalTab["tab"], approval: ApprovalObject) => {
  if (tabValue.value === "myApprovals") {
    return approval.approver?.roleName || '';
  } else if (tabValue.value === "approvalsHistory") {
    const uniqueCode = approval.approvalFlowStatus.uniqueCode;
    let status: keyof ApprovalTypes | undefined;
    if (uniqueCode === "FLOW_PENDING") status = 'pendingApproval'
    if (uniqueCode === "FLOW_APPROVED") status = `approvedByMe`
    if (uniqueCode === "FLOW_REJECTED") status = `rejectedByMe`
    if (uniqueCode === "FLOW_SKIPPED") status = `skipped`
    if (uniqueCode === "FLOW_NO_ACTION_NEEDED") status = `noActionNeeded`

    if(!status) return null;
    const icon = approvalTypes[status].icon;
    const label = approvalTypes[status].label;
     return <div className='d-flex align-items-center justify-content-end'> {icon} {label} </div>


  }else if (tabValue.value === "externalApprovals") {
    return null;
  }
  
  
  else if (tabValue.value === "externalApprovalsHistory") {
    const uniqueCode = approval.approvalFlowStatus.uniqueCode;
    let status:keyof ApprovalTypes | undefined;
    if (uniqueCode === "FLOW_APPROVED") status = `approved`
    if (uniqueCode === "FLOW_REJECTED") status = `rejected`
    if(!status) return null;
    const icon = approvalTypes[status].icon;
    const label = approvalTypes[status].label;
     return <> {icon} {label} </>
  }
  return null;

}

const ApprovalListItem: React.FC<ApprovalListItemProps> = ({ approval, onClick, style, selectedApproval, tabValue }) => {
  const details = approval.approvalFlow.sourceDetails
  const additionalDetails = details?.description ? details.additionalFields?.reduce((prev: any, item) => {
    prev[item.fieldName] = item;
    return prev
  }, {}) : null;

  const selectedList = (tabValue.value === "externalApprovals" || tabValue.value === "externalApprovalsHistory") ? selectedApproval?.approvalFlow?.approvalFlowId === approval?.approvalFlow?.approvalFlowId : selectedApproval?.approvalTaskId === approval.approvalTaskId
  return (
    <ListItem
      button
      onClick={onClick}
      style={style}
      className={`approval-list-item ${selectedList ? 'selected-list' : ''}`}
    >
      <ListItemText
        primary={<Typography component={"h4"} style={{ alignSelf: "baseline" }}> {details.heading} </Typography>}
        secondary={
          additionalDetails ? <>
            <div>
              <Typography component="span" variant="body2" color="textPrimary">
                SR# {additionalDetails.wr_number?.value}
              </Typography>
              <Typography component="span" className='ml-2' variant="body2">
                • {additionalDetails.c_compid?.value}
              </Typography>
            </div>
            


          </> : null
        }
      />
      
      <div className='text-right'>
          <Typography variant="body2" color="textSecondary" style={{ whiteSpace: "nowrap", alignSelf: "baseline" }}>
            {renderDate(tabValue, approval)}
          </Typography>
          <Typography component="span" variant="body2" color="textSecondary" style={{whiteSpace:"nowrap", textOverflow: "ellipsis", overflow:"hidden"}}>
            {renderApprovalStatus(tabValue, approval)}
          </Typography>
      </div>
    </ListItem>
  );
};

export default ApprovalListItem;