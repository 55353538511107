import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0052CC',
        },
        success: {
            main: '#006644',
        },
        error: {
            main: '#CC0000',
        },
        warning: {
            main: '#B35C00',
        },
        info: {
            main: '#0052CC',
        },
        text: {
            primary: '#1A1A1A',
            secondary: 'rgba(26, 26, 26, 0.65)',
        },
        background: {
            default: '#FFF',
            paper: '#F5F7F7',
        },
    },
    typography: {
        fontFamily: 'Calibre-R, -apple-system, Roboto, Helvetica, sans-serif',
        fontSize: 14,
        body1: {
            fontSize: 14,
            lineHeight: 1.43,
        },
        body2: {
            fontSize: 14,
            lineHeight: 1.43,
            fontWeight: 400,
        },
    },
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: 'rgba(0, 63, 45, 0.15)',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                },
            },
        },
    },
});

export default theme;