import { Button, ButtonGroup, IconButton } from '@mui/material';
import SearchBox from 'components/SearchBox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import React, { useEffect, useState } from 'react';
import ApprovalSortByBtn from './ApprovalSortbyBtn';
import { approvalConfig, ApprovalCustomComponents } from 'modules/cbre-approvals-widget/types';
import { useApprovals, useApprovalsContext } from 'modules/cbre-approvals-widget';
import FilterFormContainer from 'modules/cbre-flow-approvals-filter/components/filter-drawer';
import { useForm } from 'react-hook-form';


interface Props {
    config: approvalConfig
    components?: ApprovalCustomComponents
}

const ApprovalActions: React.FC<Props> = ({ config, components }) => {
    // Component logic goes here
    const { tabValue, config: contextConfig } = useApprovalsContext()!;
    const [searchTerm, setSearchTextForApi] = useState('');
    const [searchText, setSearchText] = useState('');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>(contextConfig.approvalsList.params.order as any || 'asc');
    
    console.log(config, contextConfig, "testing config")
    useEffect(() => {
        setSearchTextForApi("");
        setSearchText("")
        // setSortOrder('asc')
    }, [tabValue.value])

    const handleSearch = async () => {
        // const searchResults = await searchApprovals(searchTerm);
        // Update approvals state with search results
    };

    const handleSort = () => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        // Sort approvals based on new sort order
    };

    const onKeyDown = (e: any) => {
        if (e.key === 'Enter' && searchText?.trim()) {
            setSearchTextForApi(searchText?.trim());
            config.approvalsList.setQueryParams((prev) => {
                const updatedParams = { ...prev, search: searchText?.trim(), searchfields: encodeURIComponent('serialNumber,assetId,requestNumber') };
                return updatedParams;
            });
            config.approvalsList.handlePendingChanges().then((config) => {
                config.fetchApprovals()
            })

        }
    };
    const clearSearch = () => {
        updateSearchText('');
        setSearchTextForApi('');
        config.approvalsList.setQueryParams((prev) => {
            const updatedParams = { ...prev, search: '' };
            return updatedParams;
        });
        config.approvalsList.handlePendingChanges().then((config) => {
            config.fetchApprovals()
        })
    };

    const updateSearchText = (e: any) => {
        if (e.target && e.target.value) {
            setSearchText(e.target.value);
        } else {
            setSearchText('');
        }

    };

    return (
        // JSX markup goes here
        <>
            <div className="d-flex align-items-center justify-content-between mb-2" key={tabValue?.value} style={{gap: 5}}>
                <div style={{ zIndex: 0, width: "90%" }}><SearchBox
                    label="Search by Asset ID, Serial #, Request ID"
                    disabled={false}
                    value={searchText}
                    // placeholder="Search"
                    onChange={updateSearchText}
                    onKeyDown={onKeyDown}
                    clearSearch={clearSearch} />
               </div>
               <div style={{minWidth: 24}}>
                    {/* <IconButton >
                        <FilterAltIcon />
                    </IconButton> */}
                    {components?.Filter && <components.Filter config={config} />}
                </div>  

            </div>
            <ApprovalSortByBtn />
        </>
    );
};

export default ApprovalActions;