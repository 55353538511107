import { createSelector } from "reselect";
const getConfigInfo = (state) => state && state.config;
const getApiDucksInfo = (state) => state && state['cbre-flow-api-ducks'];
export const getUserInfoUrl = createSelector(
    [getConfigInfo],
    (configInfo) =>
        configInfo && configInfo.client && configInfo.client.flowApiUrl
            ? `${configInfo.client.flowApiUrl}api/users/config`
            : '',
);

export const getUserConfigData = createSelector(
    [getApiDucksInfo],
    (apiDucksInfo) => {
        const { userInfo } = apiDucksInfo?.UserInfo || {};
        const { userConfig } = userInfo || {};
        return userConfig || [];
    }
);

export const getUserInstrumentConfig = createSelector(
    [getUserConfigData],
    (userConfig) => {
        const instrumentFilterConfig = userConfig?.filter(config => config?.configType === "INSTRUMENT_FILTER");
        const instrumentConfigValues = instrumentFilterConfig?.length > 0 ? instrumentFilterConfig[0].configValue : {};
        const mappedConfigValues = {
           requestedBy: instrumentConfigValues?.requestedBy? {email: instrumentConfigValues?.requestedBy} : '',
            instrumentDescription: instrumentConfigValues?.c_assetdescription || '',
            system: instrumentConfigValues?.c_system_c_systemname? {name: instrumentConfigValues?.c_system_c_systemname} : '',
            status: instrumentConfigValues?.c_availablestatus? instrumentConfigValues?.c_availablestatus : [],
            siteTeam: instrumentConfigValues?.c_assetservicegroup? instrumentConfigValues?.c_assetservicegroup : [],
            manufacturer: instrumentConfigValues?.c_manufacturer? {c_make: instrumentConfigValues?.c_manufacturer}: '',
            instrumentOwner: instrumentConfigValues?.c_instrumentowner?.email ? instrumentConfigValues?.c_instrumentowner:  null,
            modelNumber: instrumentConfigValues?.c_modelnumber? {c_model: instrumentConfigValues?.c_modelnumber} : '',
            serialNumber: instrumentConfigValues?.c_serialnumber || '',
            assetClass: instrumentConfigValues?.c_assetclass? {c_class: instrumentConfigValues?.c_assetclass}: '',
            assetType: instrumentConfigValues?.c_assetsubclass? {c_type: instrumentConfigValues?.c_assetsubclass}: '',
            site: instrumentConfigValues?.c_site? {siteName: instrumentConfigValues?.c_site} : '',
            building: instrumentConfigValues?.c_buildingname? {c_buildingname: instrumentConfigValues?.c_buildingname}: '',
            floorLevel: instrumentConfigValues?.c_floorlevel? {c_floorarea: instrumentConfigValues?.c_floorlevel}: '',
            roomSpace: instrumentConfigValues?.c_roomspace? {c_roomspace: instrumentConfigValues?.c_roomspace}: '',
            department: instrumentConfigValues?.c_department? instrumentConfigValues?.c_department: [],
            gxpStatus: instrumentConfigValues?.c_gxpstatus? instrumentConfigValues?.c_gxpstatus : [],
            purchaseCost: instrumentConfigValues?.c_purchasecost || '',
            financialId: instrumentConfigValues?.financialId || '',
            installDate: instrumentConfigValues?.c_installdate || null,
            endOfWarrantyDate: instrumentConfigValues?.c_endofwarranty || null,
            businessCriticality: instrumentConfigValues?.c_clientcriticalitylevel ? instrumentConfigValues?.c_clientcriticalitylevel : []
        }
        return {mappedConfigValues, instrumentConfigValues};
    }
);

export const getUserApprovalFilterConfig = createSelector(
    [getUserConfigData],
    (userConfig) => {
        const approvalFilterConfig = userConfig?.filter(config => config?.configType === "APPROVAL_FILTER");
        const approvalConfigValues = approvalFilterConfig?.length > 0 ? approvalFilterConfig[0].configValue : {};
        const mappedConfigValues = {
            requestor: approvalConfigValues?.requestor || [],
            system: approvalConfigValues?.system || [],
            status: approvalConfigValues?.status || [],
            approvalCategory: approvalConfigValues?.approvalCategory || [],
            assetClass: approvalConfigValues?.assetClass || [],
            assetType: approvalConfigValues?.assetType|| [],
            site: approvalConfigValues?.site || null,
            building: approvalConfigValues?.building|| [],
            floorLevel: approvalConfigValues?.floorLevel || [],
            roomSpace: approvalConfigValues?.roomSpace || [],
            department: approvalConfigValues?.department || [],
            gxpStatus: approvalConfigValues?.gxpStatus || [],
            costCenter: approvalConfigValues?.costCenter? approvalConfigValues?.costCenter : [],
            isPastDue: approvalConfigValues?.isPastDue? approvalConfigValues?.isPastDue : false,
        }
        return {mappedConfigValues, approvalConfigValues};
    }
);

export const getUserUpComingMaintenanceConfig = createSelector(
    [getUserConfigData],
    (userConfig) => {
        const instrumentFilterConfig = userConfig?.filter(config => config?.configType === "UPCOMING_MAINT_FILTER");
        const instrumentConfigValues = instrumentFilterConfig?.length > 0 ? instrumentFilterConfig[0].configValue : {};
        const mappedConfigValues = {
            workType: instrumentConfigValues?.c_requesttype? instrumentConfigValues?.c_requesttype : [],
            assetId: instrumentConfigValues?.assetId? instrumentConfigValues?.assetId : null,
            primaryAssignment: instrumentConfigValues?.c_primaryassignment?.email ? instrumentConfigValues?.c_primaryassignment:  null,
            workRequestId: instrumentConfigValues?.work_requestId? instrumentConfigValues?.work_requestId : null,
        }
        return {mappedConfigValues, instrumentConfigValues};
    }
);

export const getUserWorkRequestConfig = createSelector(
    [getUserConfigData],
    (userConfig) => {
        const instrumentFilterConfig = userConfig?.filter(config => config?.configType === "WORKREQUEST_FILTER");
        const instrumentConfigValues = instrumentFilterConfig?.length > 0 ? instrumentFilterConfig[0].configValue : {};
        const mappedConfigValues = {
            requestedBy: instrumentConfigValues?.requestedBy?.email ?  instrumentConfigValues?.requestedBy : null,
            instrumentDescription: instrumentConfigValues?.c_assetdescription || '',
            system: instrumentConfigValues?.c_system? {name: instrumentConfigValues?.c_system} : '',
            status: instrumentConfigValues?.workrequest_timeline_status? instrumentConfigValues?.workrequest_timeline_status : [],
            arcservicetype: instrumentConfigValues?.arcservicetype? instrumentConfigValues?.arcservicetype : [],
            manufacturer: instrumentConfigValues?.c_manufacturer? {c_make: instrumentConfigValues?.c_manufacturer}: '',
            instrumentOwner: instrumentConfigValues?.c_instrumentowner?.email ? instrumentConfigValues?.c_instrumentowner:  null,
            modelNumber: instrumentConfigValues?.c_modelnumber? {c_model: instrumentConfigValues?.c_modelnumber} : '',
            serialNumber: instrumentConfigValues?.c_serialnumber || '',
            assetClass: instrumentConfigValues?.c_assetclass? {c_class: instrumentConfigValues?.c_assetclass}: '',
            assetType: instrumentConfigValues?.c_assettype? {c_type: instrumentConfigValues?.c_assettype}: '',
            site: instrumentConfigValues?.c_site? {siteName: instrumentConfigValues?.c_site} : '',
            building: instrumentConfigValues?.c_buildings? {c_buildingname: instrumentConfigValues?.c_buildings}: '',
            floorLevel: instrumentConfigValues?.c_floorlevel? {c_floorarea: instrumentConfigValues?.c_floorlevel}: '',
            roomSpace: instrumentConfigValues?.c_roomspace? {c_roomspace: instrumentConfigValues?.c_roomspace}: '',
            department: instrumentConfigValues?.c_department? instrumentConfigValues?.c_department : [],
            gxpStatus: instrumentConfigValues?.c_gxpstatus? instrumentConfigValues?.c_gxpstatus : [],
            businessCriticality: instrumentConfigValues.c_clientcriticalitylevel ? instrumentConfigValues.c_clientcriticalitylevel : [],
            purchaseCost: instrumentConfigValues?.c_purchasecost || '',
            financialId: instrumentConfigValues?.financialId || '',
            installDate: instrumentConfigValues?.c_installdate || null,
            endOfWarrantyDate: instrumentConfigValues?.c_endofwarranty || null,
        }
        return {mappedConfigValues, instrumentConfigValues};
    }
);

