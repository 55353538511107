/* eslint-disable */
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import '../styles/index.scss';
import { Portal, Typography, IconButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import apiService from 'util/apiService';
import ConfirmationModal from 'components/ConfirmationModal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import { updateDocumentList } from '../ducks/actions';
import FileService from './FileService';
import { useForm } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';
import { useFileIcon } from './useFileIcon';
import { useIntl } from 'react-intl';
import Alerts from 'components/Alerts/Index';
import DeleteIcon from '@mui/icons-material/Delete';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function DocumentFileUpload(props) {
  let {
    baseUrl,
    fieldId,
    displayUploadProgress,
    onSelectUpload,
    directAccessUrl,
    setDocumentId,
    saveUrl,
    deleteUrl,
    downloadUrl,
    fetchUrl,
    enableMultipleFileUpload,
    maxFileNoLimit,
    maxFileNameLimit,
    maxFileSizeLimit,
    fileSelectionRequired,
    fileSelectionRequiredValidationMessage,
    validFileTypes,
    validFileExtensions,
    fileSelectionDisabled,
    setOutputFiles = () => {},
    appCode,
    documentDetailHdrId,
    disableDownload,
    disableDelete,
    disableDocUpload,
    fileObj,
    folderId,
    documentId,
    hideUpload = false,
    hideDelete = false,
    handleSaveDocumentId,
  } = props;

  const { getFileIcon } = useFileIcon();
  const dispatch = useDispatch();
  let documents = [];
  const fieldIdstr = `PJM${fieldId}`;
  const documentStr = documentDetailHdrId || `${appCode}`;

  const [fileList, setFileList] = useState({});
  const [fileData, setFileData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableUpload, setDisableUpload] = useState(false);
  const [fileCountLimitExceed, setFileCountLimitExceed] = useState(false);
  const [hideDeleteValidations, setHideDeleteValidations] = useState(false);
  const [toasterMessage, setToasterMessage] = useState('');
  const [toasterState, setToasterState] = useState('');

  const [deleteItem, setDeleteItem] = useState({});
  const [existingFiles, setExistingFiles] = useState({});
  const [totalFileCount, setTotalFileCount] = useState(0);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const progressInfosRef = useRef(null);

  let files = [];

  useEffect(() => {
    if(!documentId) return undefined;
    setLoading(true);
    fetchFiles();
  }, [documentId]);

  useEffect(() => {
    if (fileCountLimitExceed) {
      setProgressInfos({ val: [] });
      setMessage([]);
    }
  }, [fileCountLimitExceed]);

  const fetchFiles = () => {
    apiService.get(`${fetchUrl}`).then((res) => {
      setLoading(false);
      mapAttachedFiles(res.data);
    });
  };

  const mapAttachedFiles = (attachedFiles) => {
    const newObj = {};
    attachedFiles?.map((item) => {
      const {
        originalFileName,
        fileId,
        mimeType,
        excludeFromLimit,
        pjmFileGroup,
        syncStatus,
        clientId,
        fileThumbnailBase64,
        description,
        fileName,
      } = item;

      const fileDetails = {
        fileName: originalFileName,
        fileId: fileId,
        mimeType,
        excludeFromLimit,
        pjmFileGroup,
        syncStatus,
        clientId,
        fileThumbnailBase64,
        description,
      };
      newObj[fileDetails.fileId] = fileDetails;
    });
    const limitCountFileLength = Object.values(newObj)?.filter(
      (x) => !x.excludeFromLimit,
    )?.length;
    setExistingFiles(newObj);
    setTotalFileCount(limitCountFileLength);
    setOutputFiles(newObj);

    // const docObj = [];
    // if (directAccessUrl) docObj[fieldIdstr] = newObj;
    // else docObj[documentStr] = newObj;

    // dispatch(updateDocumentList(docObj));
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const submitModal = async () => {
    setOpenModal(false);
    setLoading(true);
    apiService.delete(`${deleteUrl}/${documentId}/${deleteItem.fileId}`).then((res) => {
      if (res.statusCode === 200 && res.status) {
        let dataObj = Object.values(fileObj).filter(
          (item) => item.fileId !== deleteItem.fileId,
        );
        const result = dataObj.reduce((acc, item) => {
          acc[item.fileId] = { ...item };
          return acc;
        }, {});
        setOutputFiles(result);
        setExistingFiles(result);
      } else {
        setToasterState('failure');
        setToasterMessage(
          <IntlMessages
            id="fileUpload.deleteFileFailureMsg"
            values={{
              fileName: <span>{deleteItem.fileName}</span>,
            }}
          />,
        );
      }
      setLoading(false);
    });
  };

  const handleAlertClose = () => {
    setToasterState('');
    setToasterMessage('');
  };

  const handleDelete = (item) => {
    if (disableDelete) return;
    setDeleteItem(item);
    setOpenModal(true);
  };

  const handleDownload = ({ fileId, fileName, ...rest }) => {
    if (disableDownload) return;
    return FileService.downloadFile(downloadUrl, fileId, rest)
      .then((response) => response.json())
      .then(({ data }) => {
        if (data?.length) {
          const [{ blob: blobResponse, mimeType }] = data || [{}];

          const byteCharacters = atob(blobResponse);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: mimeType });

          // Create a URL for the Blob and set it as the file URL
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); // Set the filename
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url); // Clean up the URL object
        } else {
          console.error('No download link found in the response');
        }
      });
  };

  const uploadFiles = async (acceptedFiles) => {
    const _progressInfos = acceptedFiles.map((file) => ({
      percentage: 0,
      fileName: file.name,
    }));

    progressInfosRef.current = {
      val: _progressInfos,
    };

    try {
      let newDocumentId = documentId;
      if(!newDocumentId) {
        try{
          const { data } = await FileService.getDocumentId(`${baseUrl}api/documents`, { appCode });
          newDocumentId = data?.data?.documentId;
             setDocumentId(newDocumentId);
             handleSaveDocumentId?.(newDocumentId)
         } catch (error) {
          console.error('Error fetching document id', error);
         }
        }
      

      const documentsList = await Promise.all(
        acceptedFiles.map(async (file, i) => {
          const uploadedFile = await uploadFile(
            i,
            file,
            newDocumentId
            // fileUrl
          );
          return uploadedFile;
        }),
      );

      const newObj = {};
      const addObject = (newObj) => {
        setFileData((prevArray) => [...prevArray, newObj]);
      };
      documentsList.map((fileDetails) => {
        if (fileDetails) {
          newObj[fileDetails.data.fileId] = fileDetails.data;
          files.push(fileDetails.data);
          setFileData([...files, fileDetails.data]);
          addObject(fileDetails.data);
        }
      });
      let e = {};
      setExistingFiles((prevVal) => {
        e = { ...prevVal };
        return {
          ...prevVal,
          ...newObj,
        };
      });
      const outputFileList = {
        ...e,
        ...newObj,
      };

      const limitCountFileLength = Object.values(outputFileList)?.filter(
        (x) => !x.excludeFromLimit,
      )?.length;
      setTotalFileCount(limitCountFileLength);
      setDisableUpload(false);
      setOutputFiles(outputFileList);
      // const docObj = [];
      // if (directAccessUrl) docObj[fieldIdstr] = outputFileList;
      // else docObj[documentStr] = outputFileList;

      // dispatch(updateDocumentList(docObj));
    } catch (error) {
      console.log(error);
    }

    setMessage([]);
  };

  console.log(fileObj, 'fileObjj');

  const uploadFile = (idx, file, documentId) => {
    const _progressInfos = [...progressInfosRef.current.val];
    const details = {
      file,
      appCode,
      documentDetailHdrId,
      folderId,
    };
    return FileService.upload(
      saveUrl + `?documentId=${documentId}`,
      details,
      (progressEvent) => {
        _progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total,
        );
        setProgressInfos({
          val: _progressInfos,
        });
      },
    )
      .then((res) => {
        if ([201, 200].includes(res.data.statusCode) && res.data.data?.count) {
          const fileDetails = res.data.data?.recordList?.[0] || {};

          const {
            originalFileName,
            fileId,
            mimeType,
            excludeFromLimit,
            pjmFileGroup,
          } = fileDetails;
          setMessage((prevMessage) => [
            ...prevMessage,
            `Uploaded the file successfully: ${file.name}`,
          ]);
          _progressInfos[idx].percentage = 100;
          _progressInfos[idx].success = true;
          setProgressInfos({
            val: _progressInfos,
          });

          return {
            success: true,
            data: {
              fileName: originalFileName,
              originalFileName,
              fileId,
              mimeType,
              excludeFromLimit,
              pjmFileGroup,
              description: '',
            },
          };
        }
        _progressInfos[idx].percentage = 0;
        _progressInfos[idx].success = false;

        setProgressInfos({
          val: _progressInfos,
        });

        setMessage((prevMessage) => [
          ...prevMessage,
          <IntlMessages
            id="fileUpload.CouldNotUpload"
            values={{ fileName: file.name }}
          />,
        ]);

        return {
          success: false,
          data: {
            fileName: file.name,
          },
        };
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        _progressInfos[idx].success = false;
        setProgressInfos({
          val: _progressInfos,
        });

        setMessage((prevMessage) => [
          ...prevMessage,
          <IntlMessages
            id="fileUpload.CouldNotUpload"
            values={{ fileName: file.name }}
          />,
        ]);
      });
  };

  const isNewFileUploadAllowed = (
    acceptedFilesCount,
    rejectedFilesCount,
    currentFileCount,
  ) => {
    const expectedTotalFileCount =
      acceptedFilesCount + rejectedFilesCount + currentFileCount;

    if (expectedTotalFileCount > maxFileNoLimit) {
      return false;
    }
    return true;
  };
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      const checkFileCountLimit = isNewFileUploadAllowed(
        acceptedFiles.length,
        fileRejections.length,
        totalFileCount,
      );
      if (checkFileCountLimit) {
        const newFiles = {};
        for (const i of acceptedFiles) {
          newFiles[i.name] = i;
        }

        if (!onSelectUpload) {
          setOutputFiles({
            ...fileList,
            ...newFiles,
          });
        }
        let obj = {};
        setFileList((prevVal) => {
          obj = { ...prevVal, ...newFiles };
          return obj;
        });

        if (acceptedFiles.length > 0) {
          if (onSelectUpload) {
            uploadFiles(acceptedFiles);
            const docObj = [];
            if (directAccessUrl)
              docObj[fieldIdstr] = {
                ...fileList,
                ...newFiles,
              };
            else
              docObj[documentStr] = {
                ...fileList,
                ...newFiles,
              };
            dispatch(updateDocumentList(docObj));
            setDisableUpload(true);
          }
        } else {
          setProgressInfos({ val: [] });
          setMessage([]);
        }
        if (fileCountLimitExceed) setFileCountLimitExceed(false);
        if (hideDeleteValidations) setHideDeleteValidations(false);
      } else {
        setFileCountLimitExceed(true);
      }
    },
    [
      fileList,
      totalFileCount,
      fileCountLimitExceed,
      hideDeleteValidations,
      disableUpload,
    ],
  );
  const secondaryColor = {
    color: '#f44336',
    fontSize: '12px',
  };

  function nameLengthValidator(file) {
    if (documents && documents[file.name]) {
      return {
        code: 'file-name-exists',
        message: (
          <IntlMessages
            id="fileUpload.fileNameAlreadyExistsValidation"
            values={{
              fileName: <span> {file.name}</span>,
            }}
          />
        ),
      };
    }
    if (file.size > maxFileSizeLimit) {
      let intlCppMsg = '';
      if (directAccessUrl)
        intlCppMsg = (
          <IntlMessages id="fileUpload.fileSizeExceedValidationCPP" />
        );
      else
        intlCppMsg = (
          <IntlMessages id="fileUpload.fileSizeExceedValidation" />
        );
      return {
        code: 'size-too-large',
        message: intlCppMsg,
      };
    }
    if (file.name.length > maxFileNameLimit) {
      return {
        code: 'name-too-large',
        message: (
          <IntlMessages
            id="fileUpload.fileNameCharacterLimitValidation"
            values={{
              maxFileNameLimit: <span> {maxFileNameLimit}</span>,
            }}
          />
        ),
      };
    }

    return null;
  }

  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } =
    useDropzone({
      onDrop,
      maxFiles: maxFileNoLimit,
      multiple: enableMultipleFileUpload,
      validator: nameLengthValidator,
      noClick: true,
      noKeyboard: true,
      disabled: fileSelectionDisabled || disableUpload,
      accept: validFileTypes,
    });
  const {
    reset,
    control,
    errors,
    setValue,
    register,
    getValues,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: [{ hierarchyFieldConfigs: {} }],
  });

  useEffect(() => {
    return () => {
      // setSelectedTags([]);
      // setSelectedTagCodes([]);
    };
  }, []);

  const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      <div className="document-file-upload">
        <div className="dropzone-card">
          <div className="dropzone" style={{display: hideUpload ? 'none' : 'block'}}>
            <div
              {...getRootProps({
                className: 'dropzone-file-btn',
              })}
            >
              <input {...getInputProps()} data-testid="dropzone-element" />
              <p>
                <IntlMessages id="fileUpload.dragDrop" />
                &nbsp;
                <IntlMessages id="fileUpload.or" />
                &nbsp;
                <a
                  className="text-primary"
                  style={{ textDecoration: 'underline' }}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    open(e);
                  }}
                  disabled={
                    fileSelectionDisabled || disableUpload || disableDocUpload
                  }
                >
                  <IntlMessages id="fileUpload.chooseFromFolder" />
                </a>
              </p>

              {/* <p
                style={{
                  marginTop: '1rem',
                  marginBottom: '0',
                }}
              >
                <IntlMessages
                  id="fileUpload.maxFileNoLimitValidation"
                  values={{
                    maxFileNoLimit: <span> {maxFileNoLimit}</span>,
                  }}
                />
              </p>
              <p>
                {directAccessUrl ? (
                  <IntlMessages id="fileUpload.fileSizeExceedValidationCPP" />
                ) : (
                  <IntlMessages id="fileUpload.fileSizePrincipleLiteExceedValidation" />
                )}
                <br />
                <IntlMessages id="textbox.acceptedFileType" />
                {validFileExtensions?.length > 0 && (
                  <span>{validFileExtensions.join(', ')}</span>
                )}
              </p> */}
            </div>
          </div>
          <div className="dropzone-content">
            <div className="file-required-err">
              <p className="file-required-err-msg">
                {fileSelectionRequired &&
                  !loading &&
                  documents &&
                  Object.values(documents)?.filter((x) => !x.excludeFromLimit)
                    ?.length === 0 &&
                  fileSelectionRequiredValidationMessage}
                {fileSelectionRequired &&
                  documents &&
                  Object.values(documents)?.filter((x) => !x.excludeFromLimit)
                    ?.length === 0 &&
                  fileCountLimitExceed &&
                  ': '}
                {fileCountLimitExceed && (
                  <IntlMessages
                    id="fileUpload.maxFileNoLimitValidation"
                    values={{
                      maxFileNoLimit: <span> {maxFileNoLimit}</span>,
                    }}
                  />
                )}
              </p>
            </div>
            {onSelectUpload &&
              displayUploadProgress &&
              progressInfos &&
              progressInfos.val.length > 0 && (
                <List>
                  {progressInfos.val.map((progressInfo, index) => {
                    return progressInfo.success === undefined ||
                      progressInfo.success === false ? (
                      <ListItem key={progressInfo.fileName}>
                        <ListItemText
                          id="switch-list-label-wifi"
                          primary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                {progressInfo.fileName}
                              </Typography>
                              {progressInfo?.success === true && (
                                <span
                                  style={{
                                    color: '#006A4D',
                                    fontSize: '11px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {'     '}
                                  &nbsp;&nbsp;Upload Success
                                </span>
                              )}
                              {progressInfo?.success === false && (
                                <span
                                  style={{
                                    color: '#f44336',
                                    fontSize: '11px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {'     '}
                                  &nbsp;&nbsp;Upload Fail
                                </span>
                              )}
                            </React.Fragment>
                          }
                          secondary={
                            <LinearProgressWithLabel
                              value={progressInfo.percentage}
                            />
                          }
                        />
                      </ListItem>
                    ) : null;
                  })}
                </List>
              )}
            {fileRejections.length > 0 &&
              !fileCountLimitExceed &&
              !hideDeleteValidations && (
                <List
                  subheader={
                    <ListSubheader>
                      <IntlMessages id="fileUpload.rejectedFilesHeader" />
                    </ListSubheader>
                  }
                >
                  {fileRejections.map(({ file, errors }) => (
                    <ListItem key={file.path}>
                      <ListItemIcon
                        style={{
                          color: '#f44336',
                        }}
                      >
                        <i className="zmdi zmdi-alert-circle-o zmdi-hc-fw zmdi-hc-lg" />
                      </ListItemIcon>
                      <ListItemText
                        id="switch-list-label-wifi"
                        primary={file.path}
                        secondaryTypographyProps={{
                          style: secondaryColor,
                        }}
                        secondary={
                          errors[0].code === 'file-invalid-type' ? (
                            <IntlMessages
                              id="fileUpload.fileTypeInvalidValidation"
                              values={{
                                validExtension: (
                                  <span>{validFileExtensions.join(', ')}</span>
                                ),
                              }}
                            />
                          ) : (
                            errors[0].message
                          )
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            <div className="activity-table w-100">
              <div>
                {fileObj &&
                  Object.values(fileObj) &&
                  Object.values(fileObj).length > 0 &&
                  Object.values(fileObj).map((item, index) => (
                    <>
                      <div
                        className="align-items-center d-flex file-uploaded-item justify-content-between p-2 mb-1"
                        key={index}
                        style={{ background: '#F5F7F7', borderRadius: 4 }}
                      >
                        <div className="file-uploaded-item-name d-flex align-items-center">
                          <IconButton
                            className="m-0"
                            onClick={() => handleDownload(item)}
                          >
                            {getFileIcon('DOWNLOAD')}
                          </IconButton>
                          <p className="m-0">{item?.fileName}</p>
                        </div>
                        <div className="file-uploaded-item-delete">
                          {!item.excludeFromLimit && !hideDelete && (
                            <IconButton
                              disabled={disableDelete}
                              className="m-0"
                              onClick={() => handleDelete(item)}
                            >
                              <DeleteIcon
                                style={{
                                  color: '#1A1A1A99',
                                }}
                              />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <ConfirmationModal
          content={
            <IntlMessages
              id="fileUpload.deleteConfirmationMessage"
              values={{
                fileName: <span>{deleteItem.fileName}</span>,
              }}
            />
          }
          open={openModal}
          fcName="fc-black"
          onClose={(proceed) => {
            if (proceed) return submitModal();
            return closeModal();
          }}
          onConfirmAction={submitModal}
        />
        <Portal container={document.body}>
          <Alerts
            alertState={toasterState}
            handleRequestClose={handleAlertClose}
            message={toasterMessage}
          />
          <Backdrop open={loading} className="main-backdrop-custom">
            <CircularProgress className="main-loader-custom" />
          </Backdrop>
        </Portal>
      </div>
    </>
  );
}

export default DocumentFileUpload;
