import { useState, useCallback } from 'react';

interface Filter {
  [key: string]: string[] | string;
}

const useApprovalFilter = () => {
  const [currentFilter, setCurrentFilter] = useState<Filter>({});
  const [filterPayload, setFilterPayload] = useState<string>("");

  const generateFilterPayload = useCallback(() => {
    return Object.entries(currentFilter)
      .map(([key, values]) => `${key}=${Array.isArray(values) ? values.join(',') : values}`)
      .join('|');
  }, [currentFilter]);

  return {
    currentFilter,
    setCurrentFilter,
    filterPayload,
    setFilterPayload
  };
};

export default useApprovalFilter;