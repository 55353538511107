
/* eslint-disable */

// Very important message , this component has been used in captial plan also , so consider this before changing
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import IntlMessages from 'util/IntlMessages';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import '../styles/DocumentsContainer.scss';
import {
  getFilesDelete,
  getFilesUpdate,
} from './../ducks/selectors';
import DocumentFileUpload from '.';
import { getFilesDownload, getFilesList } from '../ducks/selectors';

export default function DocumentsContainer({
  open = false,
  closeDocDialog = ()=>{},
  folderId = '5',
  documentId: parentDocumentId = '',
  hideUpload = false,
  hideDelete = false,
  handleSaveDocumentId = (id)=>{},
  appCode = 'ELIS',
  baseUrl,
  ...rest
}) {
  const types = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    ".dwf",
    ".dwg",    
    'image/tiff'
  ];
  const [documentId, setDocumentId] = useState(parentDocumentId);
  const extensions = ['jpg', 'jpeg', 'png', 'xlsx', 'pptx', 'docx', 'pdf', 'cad', 'dwg', 'tiff'];
  const enableMultipleFileUpload = true;
  const maxFileNoLimit = 10;
  const maxFileNameLimit = 50;
  const maxFileSizeLimit = 150 * 1024 * 1024;
  const onSelectUpload = true;
  const attachmentRequired = false;
  const getFilesUpdateUrl = useSelector(getFilesUpdate);
  const getFileFetchUrl = useSelector(getFilesList(documentId));
  const getFileDownloadUrl = useSelector(getFilesDownload);
  const fileDeleteUrl = useSelector(getFilesDelete);
  const fileUploadUrl = `${getFilesUpdateUrl}?appCode=${appCode}`;
  const fileDownloadUrl = `${getFileDownloadUrl}`;
  const fileFetchUrl = `${getFileFetchUrl}`;
  const [fileObj, setObj] = useState();

  useEffect(() => {
    if(parentDocumentId !== documentId) setDocumentId(parentDocumentId);
  }, [parentDocumentId]);

  const setOutputFiles = data => {
    // get output files
    setObj(data);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  return (
    <div  className="pl-documents-dialog"
    >
     
          <DocumentFileUpload
            documentId = {documentId}
            hideUpload = {hideUpload}
            hideDelete = {hideDelete}
            enableMultipleFileUpload={enableMultipleFileUpload}
            maxFileNoLimit={maxFileNoLimit}
            maxFileNameLimit={maxFileNameLimit}
            maxFileSizeLimit={maxFileSizeLimit}
            onSelectUpload={onSelectUpload}
            saveUrl={fileUploadUrl}
            deleteUrl={`${fileDeleteUrl}`}
            downloadUrl={`${fileDownloadUrl}`}
            setDocumentId={setDocumentId}
            handleSaveDocumentId={handleSaveDocumentId}
            fetchUrl={fileFetchUrl}
            displayUploadProgress={true}
            validFileTypes={types}
            validFileExtensions={extensions}
            fileSelectionLabel={
              <IntlMessages id="cbre-pjm-principal-lite-project-documents.label.attachedFiles" />
            }
            fileSelectionRequired={attachmentRequired}
            fileSelectionRequiredValidationMessage={
              <IntlMessages id="cbre-pjm-principal-lite-project-documents.label.documentRequired" />
            }
            fileSelectionDisabled={false}
            fetchAfterDelete={true}
            appCode={appCode}
            disableDocUpload={false}
            disableDownload={false}
            disableDelete={false}
            setOutputFiles={setOutputFiles}
            fileObj={fileObj}
            closeDocDialog={closeDocDialog}
            folderId={folderId}
            baseUrl={baseUrl}
            {...rest}
          />
    </div>
  );
}
