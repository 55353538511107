
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

interface ConfirmDialogProps {
  open: boolean;
  onClose: (proceed: boolean) => void;
  title: React.ReactNode
  content: React.ReactNode
  isSecondaryBtnRequired?: boolean
  primaryBtnContent?: string
  secondaryBtnContent?: string
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, onClose, title, content, secondaryBtnContent = "No", isSecondaryBtnRequired = true, primaryBtnContent = 'Yes' }) => {
  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle> { title } </DialogTitle>
      <DialogContent>
        <DialogContentText>
          { content }
        </DialogContentText>
      </DialogContent>
      <DialogActions className='p-3'>
        { isSecondaryBtnRequired && <Button variant='outlined' onClick={() => onClose(false)} color="primary">
          {secondaryBtnContent}
        </Button>}
        <Button variant='contained' onClick={() => onClose(true)} color="primary">
          {primaryBtnContent}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
