import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { CheckCircle } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { ApprovalTypes, IApprovalTab } from '../types';

export const tabs: IApprovalTab["tab"][] = [
    { label: 'My Approval Needed', value: 'myApprovals', icon: <CircleIcon style={{ color: "#ef4444", fontSize: 14 }} /> },
    { label: 'My Approval History', value: 'approvalsHistory', icon: <CircleOutlinedIcon style={{ color: "#ef4444", fontSize: 14 }} />, hasDivider: true },
    { label: 'External Approval Needed', value: 'externalApprovals', icon: <CircleIcon style={{ color: "#818cf8", fontSize: 14 }} /> },
    { label: 'External Approval History', value: 'externalApprovalsHistory', icon: <CircleOutlinedIcon style={{ color: "#818cf8", fontSize: 14 }} /> },
];

export const approvalTypes:ApprovalTypes = {
    pendingMyApproval: { label: "Pending my approval", icon: <CircleOutlinedIcon style={{ color: "#FF9742", fontSize: 14 }} />  },
    pendingExternalApproval: { label: "Pending external approval", icon: <CircleOutlinedIcon style={{ color: "#FF9742", fontSize: 14 }} />  },
    pendingApproval: { label: "Pending Approval", icon: <CircleOutlinedIcon style={{ color: "#8585E0", fontSize: 14 }} />  },
    approvedByMe: { label: "Approved by me", icon: <CheckCircle style={{ color: '#50AF78', fontSize: 14 }} />   },
    rejectedByMe: { label: "Rejected by me", icon: <CancelIcon style={{ color: '#FF543E', fontSize: 14 }} />   },
    approved: { label: "Approved", icon: <CheckCircle style={{ color: '#50AF78', fontSize: 14 }} />  },
    rejected: { label: "Rejected", icon: <CancelIcon style={{ color: "#FF543E", fontSize: 14 }} />   },
    skipped: { label: "No Action Taken", icon: <RemoveOutlinedIcon style={{ color: '#E7E7F9', fontSize: 14 }} />   },
    noActionNeeded: { label: "No Action Needed", icon: <RemoveOutlinedIcon style={{ color: '#E7E7F9', fontSize: 14 }} />   },
}

export const renderFlowStatus = (status: string) => {
    if (status === "FLOW_PENDING") return 'pendingApproval'
    if (status === "FLOW_APPROVED") return `approved`
    if (status === "FLOW_REJECTED") return `rejected`
    if (status === "FLOW_SKIPPED") return `skipped`
    if (status === "FLOW_NO_ACTION_NEEDED") return `noActionNeeded`
}
