import React, { useState } from 'react';
import {
    Box,
    Typography,
    styled,
    Collapse,
    IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {formatDateTime, formatUserName} from './formatters';

interface HistoryItemProps {
    status: string;
    date: string;
    events: any;
}

const HistoryItemContainer = styled(Box)(({ theme }) => ({
    alignItems: 'stretch',
    borderBottom: '1px solid var(--Misc-Dividers-Divider, rgba(0, 63, 45, 0.15))',
    display: 'flex',
    width: '100%',
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingTop: '8px',
    paddingBottom: '12px',
    flexDirection: 'column',
    justifyContent: 'start',
}));

const MainSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'start',
}));

const Row = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: '8px',
    lineHeight: '1.43',
    justifyContent: 'center',
    flexWrap: 'wrap',
}));

const Label = styled(Typography)(({ theme }) => ({
    color: 'var(--Text-Dark-Text-Dark, #1A1A1A)',
    fontWeight: 500,
    alignSelf: 'stretch',
    marginTop: 'auto',
    marginBottom: 'auto',
    flex: 1,
    flexShrink: 1,
    flexBasis: '0%',
}));

const DateText = styled(Typography)(({ theme }) => ({
    color: '#1a1a1a',
    textAlign: 'right',
    fontWeight: 400,
    alignSelf: 'stretch',
    marginTop: 'auto',
    marginBottom: 'auto',
}));

const Author = styled(Typography)(({ theme }) => ({
    color: 'var(--Text-Dark-Text-Dark, #1A1A1A)',
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: '4px',
}));

const Comments = styled(Typography)(({ theme }) => ({
    color: '#1a1a1a',
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: '8px',
}));

const SkippedApproversContainer = styled(Box)(({ theme }) => ({
    justifyContent: 'center',
    alignItems: 'stretch',
    borderRadius: '4px',
    backgroundColor: 'var(--Backgrounds-Background-2nd, #F5F7F7)',
    display: 'flex',
    marginTop: '8px',
    width: '100%',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '4px',
    paddingBottom: '4px',
    flexDirection: 'column',
}));

const SkippedApproversHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    color: '#000',
    fontWeight: 400,
    lineHeight: '1.43',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
}));

const SkippedApproversLabel = styled(Typography)(({ theme }) => ({
    alignSelf: 'stretch',
    marginTop: 'auto',
    marginBottom: 'auto',
    flex: 1,
    flexShrink: 1,
    flexBasis: '0%',
}));

const SkippedApproversSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: '10px',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    color: 'var(--Text-Dark-Text-Dark, #1A1A1A)',
    justifyContent: 'start',
}));

const SkippedApproversTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    lineHeight: '1.43',
}));

const SkippedApproversContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: '4px',
    width: '100%',
    paddingLeft: '16px',
    paddingBottom: '4px',
    flexDirection: 'column',
    alignItems: 'stretch',
    fontWeight: 400,
    lineHeight: '20px',
    justifyContent: 'start',
}));

const ApproverRow = styled(Box)(({ theme }) => ({
    paddingBottom: '10px',
    alignItems: 'start',
    borderBottom: '1px solid var(--Misc-Dividers-Divider, rgba(0, 63, 45, 0.15))',
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
}));

const LastApproverRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: '4px',
    width: '100%',
    paddingBottom: '10px',
    alignItems: 'start',
    justifyContent: 'start',
}));

const HistoryItem: React.FC<HistoryItemProps> = ({ status, date, events }) => {
    const [expanded, setExpanded] = useState(false);
    const [reminderExpanded, setReminderExpanded] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const toggleReminderExpand = () => {
        setReminderExpanded(!reminderExpanded);
    };

    const getStatusLabel = () => {
        switch (status) {
            case 'SNAPLOGIC':
            case 'APPROVAL_FLOW_INITIATED':
                return 'Submitted';
            case 'DECISION_GIVEN_APPROVED':{
                const decisionBy = events.decisionGivenBy;
                const approverInfo = `${decisionBy.firstName} ${decisionBy.lastName} (${decisionBy.role})`;
                return (
                    <>
                        Approved by {approverInfo}
                        {events.decisionNote && (
                            <div>
                                <strong>Comments:</strong> <span className="comments">{events.decisionNote}</span>
                            </div>
                        )}
                    </>
                );
            }
            case 'DECISION_GIVEN_REJECTED':{
                const decisionBy = events.decisionGivenBy;
                const approverInfo = `${decisionBy.firstName} ${decisionBy.lastName} (${decisionBy.role})`;
                return (
                    <>
                        Rejected by {approverInfo}
                        {events.decisionNote && (
                            <div>
                                <strong>Comments:</strong> <span className="comments">{events.decisionNote}</span>
                            </div>
                        )}
                    </>
                );
            }
            case 'KAFKA_NEW_TOKEN_RECEIVED':
                return 'Approvals started';
            case 'FINAL_APPROVE':
                return `Approval Flow Completed${
                    events.emailSentTo ? `\nNotification sent to: ${events.emailSentTo.join(', ')}` : ''
                }`;

            case 'FINAL_REJECTION':
                return `Final Rejection${
                    events.emailSentTo ? `\nNotification sent to: ${events.emailSentTo.join(', ')}` : ''
                }`;

            case 'DELEGATE_ASSIGNED': {
                const approverInfo = `${events.approver.name} (${events.approver.role})`;
                const delegatedUserInfo = `${events.delegatedUser.name} (${events.delegatedUser.role})`;
                return `Delegate Assigned: ${approverInfo} delegated to ${delegatedUserInfo} from ${formatDateTime(events.delegateStartDateTime)} to ${formatDateTime(events.delegateEndDateTime)}`;
            }

            case 'DELEGATE_EXPIRED': {
                const approverInfo = `${events.approver.name} (${events.approver.role})`;
                const delegatedUserInfo = `${events.delegatedUser.name} (${events.delegatedUser.role})`;
                return `Delegate Expired: ${approverInfo} delegated to ${delegatedUserInfo} from ${formatDateTime(events.delegateStartDateTime)} to ${formatDateTime(events.delegateEndDateTime)}`;
            }

            case 'APPROVER_REMOVED': {
                if (events.approver) {
                    const approverName = events.approver.name || `${events.approver.firstName || ''} ${events.approver.lastName || ''}`.trim();
                    const approverRole = events.approver.role || '';
                    return `Approver Removed: ${approverName}${approverRole ? ` (${approverRole})` : ''}`;
                }
                return 'Approver Removed';
            }

            case 'REMINDER_SENT': {
                const reminderSentTo = (() => {
                    if (events?.groupedReminders) {
                        if (Array.isArray(events.groupedReminders)) {
                            return events.groupedReminders.map((user: Record<string, any>) => formatUserName(user)).join(', ');
                        }
                        if (typeof events.groupedReminders === 'object') {
                            return formatUserName(events.groupedReminders);
                        }
                        return events.groupedReminders;
                    } else {
                        return events.reminderSentTo;
                    }
                })();
                const reminderSentBy = formatUserName(events.reminderSentBy);
                return (
                    <>
                        Reminders sent {!events?.groupedReminders?.length && <>: {formatUserName(events.reminderSentTo)} - {events.reminderSentTo.email}</>}<br />
                        Sent by: {reminderSentBy}<br />
                        Approval Due Date: {formatDateTime(events.approvalDueDate)}<br />
                        {events.reminderDays && <>Reminder Days: {events.reminderDays} </>}
                    </>
                );
            }
            default:
                return status.replace(/_/g, ' ');
        }
    };

    const renderAuthorInfo = () => {
        let authorName = '';
        let authorRole = '';
        let authorEmail = '';

        if (status === 'APPROVAL_FLOW_INITIATED' && events.requestedBy) {
            authorName = formatUserName(events.requestedBy);
            authorRole = events.requestedBy.role || '';
            authorEmail = events.requestedBy.email || '';
        } else if (status === 'DECISION_GIVEN_APPROVED' || status === 'DECISION_GIVEN_REJECTED') {
            const decisionBy = events.decisionGivenBy;
            if (decisionBy) {
                authorName = `${decisionBy.firstName || ''} ${decisionBy.lastName || ''}`.trim();
                authorRole = decisionBy.role || '';
                authorEmail = decisionBy.email || '';
            }
        } else if (status === 'APPROVER_REMOVED' && events.approver) {
            authorName = events.approver.name || `${events.approver.firstName || ''} ${events.approver.lastName || ''}`.trim();
            authorRole = events.approver.role || '';
            authorEmail = events.approver.email || '';
        }

        return `${authorName}${authorRole ? ' - ' + authorRole : ''} ${authorEmail}`;
    };

    const hasSkippedApprovers = events.skippedApproversList && events.skippedApproversList.length > 0;
    const hasRemindedApprovers = events.groupedReminders && events.groupedReminders.length > 0;
    const hasNewApproverTasks = events.approversWithNewTask && events.approversWithNewTask.length > 0;
    const hasRemovedApprover = status === 'APPROVER_REMOVED' && events.approver;
    const comments = events.decisionNote || events.comments || '';

    return (
        <HistoryItemContainer>
            <MainSection>
                <Row>
                    <Label>{getStatusLabel()}</Label>
                    <DateText>{date}</DateText>
                </Row>
               {/* <Author>{renderAuthorInfo()}</Author>*/}

                {comments && (
                    <Comments>{comments}</Comments>
                )}

                {(hasSkippedApprovers || hasNewApproverTasks) && (
                    <SkippedApproversContainer>
                        <SkippedApproversHeader>
                            <SkippedApproversLabel>Additional Updates </SkippedApproversLabel>
                            <IconButton size="small" onClick={toggleExpand}>
                                <ExpandMoreIcon />
                            </IconButton>
                        </SkippedApproversHeader>

                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            {hasRemovedApprover && <SkippedApproversSection>
                                <SkippedApproversTitle>
                                    Approver Removed
                                </SkippedApproversTitle>
                                <SkippedApproversContent>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt:1 }}>
                                        <Typography>
                                            {events.approver.name || `${events.approver.firstName || ''} ${events.approver.lastName || ''}`.trim()}
                                            {events.approver.role ? ` - ${events.approver.role}` : ''}
                                        </Typography>
                                        <Typography sx={{ mt: 1 }}>
                                            {events.approver.email || ''}
                                        </Typography>
                                        {events.approver.generalizationId && (
                                            <Typography sx={{ mt: 1 }}>
                                                Group ID: {events.approver.generalizationId}
                                            </Typography>
                                        )}
                                        {events.groupId && (
                                            <Typography sx={{ mt: 1 }}>
                                                Group: {events.groupId}
                                            </Typography>
                                        )}
                                        {events.when && (
                                            <Typography sx={{ mt: 1 }}>
                                                Stage: {events.when}
                                            </Typography>
                                        )}
                                    </Box>
                                </SkippedApproversContent>
                            </SkippedApproversSection>
                            }
                            {hasSkippedApprovers && <SkippedApproversSection>
                                <SkippedApproversTitle>
                                    {events.skippedApproversList.length} skipped approvers
                                </SkippedApproversTitle>
                                <SkippedApproversContent>
                                    {events.skippedApproversList.map((approver: any, index: number) => {
                                        const isLast = index === events.skippedApproversList.length - 1;
                                        const Component = isLast ? LastApproverRow : ApproverRow;
                                        return (
                                            <Component key={index}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt:1 }}>
                                                    <Typography>
                                                        {formatUserName(approver)} - {approver.role}
                                                    </Typography>
                                                    <Typography sx={{ mt: 1 }}>
                                                        {approver.email}
                                                    </Typography>
                                                </Box>
                                            </Component>
                                        );
                                    })}
                                </SkippedApproversContent>
                            </SkippedApproversSection>
                            }
                            {hasNewApproverTasks && <SkippedApproversSection>
                                <SkippedApproversTitle>
                                    {events.approversWithNewTask.length} new approver tasks sent to following users
                                </SkippedApproversTitle>
                                <SkippedApproversContent>
                                    {events.approversWithNewTask.map((approver: any, index: number) => {
                                        const isLast = index === events.approversWithNewTask.length - 1;
                                        const Component = isLast ? LastApproverRow : ApproverRow;
                                        return (
                                            <Component key={index}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt:1 }}>
                                                    <Typography>
                                                        {formatUserName(approver)} - {approver.role}
                                                    </Typography>
                                                    <Typography sx={{ mt: 1 }}>
                                                        {approver.email}
                                                    </Typography>
                                                </Box>
                                            </Component>
                                        );
                                    })}
                                </SkippedApproversContent>
                            </SkippedApproversSection>
                            }
                        </Collapse>
                    </SkippedApproversContainer>
                )}
                {(hasRemindedApprovers) && (
                    <SkippedApproversContainer>
                        <SkippedApproversHeader>
                            <SkippedApproversLabel>Reminder received users list </SkippedApproversLabel>
                            <IconButton size="small" onClick={toggleReminderExpand}>
                                <ExpandMoreIcon />
                            </IconButton>
                        </SkippedApproversHeader>

                        <Collapse in={reminderExpanded} timeout="auto" unmountOnExit>
                            {hasRemindedApprovers && <SkippedApproversSection>
                                <SkippedApproversTitle>
                                    Sent reminders to {events?.groupedReminders?.length} users
                                </SkippedApproversTitle>
                                <SkippedApproversContent>
                                    {events?.groupedReminders?.map((approver: any, index: number) => {
                                        const isLast = index === events?.groupedReminders?.length - 1;
                                        const Component = isLast ? LastApproverRow : ApproverRow;
                                        return (
                                            <Component key={index}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt:1 }}>
                                                    <Typography>
                                                        {formatUserName(approver)} - {approver.role}
                                                    </Typography>
                                                    <Typography sx={{ mt: 1 }}>
                                                        {approver.email}
                                                    </Typography>
                                                </Box>
                                            </Component>
                                        );
                                    })}
                                </SkippedApproversContent>
                            </SkippedApproversSection>
                            }
                        </Collapse>
                    </SkippedApproversContainer>
                )}
            </MainSection>
        </HistoryItemContainer>
    );
};

export default HistoryItem;