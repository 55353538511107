import { ReactComponent as PDFIcon } from 'assets/images/icons/PDF.svg';
import { ReactComponent as XLSIcon } from 'assets/images/icons/XLS.svg';
import { ReactComponent as DOCIcon } from 'assets/images/icons/DOC.svg';
import { ReactComponent as CADIcon } from 'assets/images/icons/CAD.svg';
import { ReactComponent as JPGIcon } from 'assets/images/icons/JPG.svg';
import { ReactComponent as PNGIcon } from 'assets/images/icons/PNG.svg';
import { ReactComponent as PPTIcon } from 'assets/images/icons/PPT.svg';
import { ReactComponent as TIFFIcon } from 'assets/images/icons/TIFF.svg';
import { ReactComponent as DWGICON } from 'assets/images/icons/DWG.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/icons/download.svg';

const FileIcons = (file: string) => {
  switch (file) {
    case 'PDF':
      return <PDFIcon />;
    case 'XLSX':
      return <XLSIcon />;
    case 'XLS':
      return <XLSIcon />;
    case 'DOC':
    case 'DOCX':
      return <DOCIcon />;
    case 'CAD':
      return <CADIcon />;
    case 'JPEG':
    case 'JPG':
      return <JPGIcon />;
    case 'PNG':
      return <PNGIcon />;
    case 'PPT':
    case 'PPTX':
      return <PPTIcon />;
    case 'TIFF':
      return <TIFFIcon />;
    case 'TIF':
      return <TIFFIcon />;
    case 'DWG':
    case 'DWF':
      return <DWGICON />;
    case 'DOWNLOAD':
      return <DownloadIcon />;
    default:
      return '';
  }
};
export const useFileIcon = () => {
  const getFileIcon = (fileName: string) => {
    const nameSplit = fileName?.split('.') ?? [];
    const fileIcon = nameSplit.at(-1)?.toUpperCase() ?? '';
    return FileIcons(fileIcon);
  };
  return { getFileIcon };
};
