import { useState, useEffect } from 'react';
import { ReturnUseApprovalsProps, type useApprovalsProps } from '../types';
// import useApprovalsListApi from './useApprovalsList';
import useApprovalsCount from './useApprovalsCounts';
import useApprovalsListApi from 'modules/cbre-approvals-list/components/useApprovalsList';
import { useApprovalDetails } from 'modules/cbre-approval-details-widget';
import useApprovalFilter from './useApprovalFilter';

const useApprovals = ({
  url: baseUrl,
}: useApprovalsProps): ReturnUseApprovalsProps => {
  const approvalsList = useApprovalsListApi({
    url: baseUrl,
    queryParams: {
      pageNumber: 1,
      pageSize: 20,
      order: 'asc',
      searchfields: 'serialNumber,assetId,requestNumber',
    },
  });
  const approvalsCount = useApprovalsCount({ url: baseUrl });
  const approvalDetails = useApprovalDetails({ url: baseUrl });
  const approvalFilter = useApprovalFilter()

  return { approvalsList, approvalsCount, approvalDetails, baseUrl, approvalFilter };
};

export default useApprovals;
