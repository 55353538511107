import { Typography, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useApprovalsContext } from 'modules/cbre-approvals-widget/components/approvalContext';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom'
import { type ApprovalDetailDataObject } from '../types';
import { handleDownload } from './approval-details.service';
import { get } from "lodash";
import ApprovalDocumentsContainer from './DocumentsContainer';
import { DocumentsContainer } from 'modules/cbre-document-file-upload-download';

interface ApprovalDetailsProps {
    mode: 'mini' | 'large';
    apiData: any;
    selectedApproval?:any;
    baseUrl:string
}

type detail = ApprovalDetailDataObject["data"]["sectionDetails"][0]["additionalFields"][0];

const ApprovalDetailsView: React.FC<ApprovalDetailsProps> = ({ mode, apiData, selectedApproval, baseUrl }) => {

    const { approvalDetails, cdfDetails } = apiData || {};
    const fieldSchema: Array<ApprovalDetailDataObject["data"]> = cdfDetails?.fieldSchema || [];
    const handleAssetId = (detail: detail) => {
        const id = get(approvalDetails, detail.paramVariableName);
        const url =`${window.location.origin}${detail.url}${id}`
        const additionalAttr:any = {};
        if((detail as any).targetWindow === "NEW") additionalAttr.target="_blank"
        return <div><a className='asset-id-text' href={id ? url: "#"} {...additionalAttr}><Typography>{ get(approvalDetails, detail.accessorKey) }</Typography></a> </div>
    }

    const handleAttachment = (detail: detail) => {
        return <Box className="attachments-list">
            {/* <Typography variant="body2">Attachments</Typography> */}
            {/* {approval.attachments.map((attachment: any, index: any) => (
                            <div className='d-flex align-items-center justify-content-between' key={index}>
                                <Typography>{attachment}</Typography>
                                <IconButton onClick={() => { handledownload(url, filename) }}>
                                    <FileDownloadIcon />
                                </IconButton>
                            </div>
                        ))} */}
             {/* <ApprovalDocumentsContainer documentId = {selectedApproval?.approvalFlow?.documentId}/>            */}
             <DocumentsContainer baseUrl={baseUrl} documentId = {selectedApproval?.approvalFlow?.documentId} hideUpload={true} hideDelete={true} />           
        </Box>
    }

    const renderData = (detail: detail, approvalDetails:any) => {
        //test
        if (detail.isNavigation) return handleAssetId(detail)
        if (detail.isAttachment) return handleAttachment(detail)

            console.log(detail, approvalDetails, 'approvalDetails')
        return <Typography>{ get(approvalDetails, detail.accessorKey) }</Typography>
    }

    

    return (<>{fieldSchema.map(field => {
        return field.sectionDetails.map((item) => {
            return <Box className="details-section" key={item.sectionName}>
                <Typography variant="h6">{item.sectionName}</Typography>
                {
                    item.additionalFields.map((detail) => {

                        return <Grid container className="detail-item" key={detail.fieldName}>
                            <Grid xs={12}>
                                <Typography variant="body2" className='detail-item-title'>{detail.displayName}</Typography>
                            </Grid>
                            <Grid xs={12}>
                                {renderData(detail, approvalDetails)}
                            </Grid>
                        </Grid>
                    }
                    )
                }
            </Box>
        })
    })}</>)


};

export default ApprovalDetailsView;