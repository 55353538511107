/* eslint-disable */
import moment from 'moment';
import { createSelector } from 'reselect';

const documentModule = 'cbre-pjm-principal-lite-project-documents';
const getNavSectionData = (state) =>
  state &&
  state[documentModule] &&
  state[documentModule].documentNavSectionsData;

const getProjectData = (state) =>
  state && state[documentModule] && state[documentModule].projectDetails;
const getConfigInfo = (state) => state && state.config;

export const getUsersLookupApiUrl = createSelector(
  [getConfigInfo],
  (configInfo) =>
    configInfo && configInfo.client && configInfo.client.coreUrl
      ? `${configInfo.client.coreUrl}user`
      : '',
);
export const getContactApiUrl = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.coreUrl
    ? `${configInfo.client.coreUrl}contact`
    : '',
);
export const getLookupApiUrl = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.coreUrl
    ? `${configInfo.client.coreUrl}lookup/`
    : '',
);

export const getDocRepository = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.principalLiteDocApiUrl
    ? `${configInfo.client.principalLiteDocApiUrl}apps/PJM05/directory/`
    : '',
);
export const moveDocRepository = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.principalLiteDocApiUrl
    ? `${configInfo.client.principalLiteDocApiUrl}apps/PJM05/action/`
    : '',
);

export const getDocDownload = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.principalLiteDocApiUrl
    ? `${configInfo.client.principalLiteDocApiUrl}apps/PJM05/download/`
    : '',
);

export const postDocuments = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.principalLiteDocApiUrl
    ? `${configInfo.client.principalLiteDocApiUrl}apps/PJM05/folder`
    : '',
);

export const getTagsUrl = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.principalLiteDocApiUrl
    ? `${configInfo.client.principalLiteDocApiUrl}apps/PJM05/systemTag`
    : '',
);

export const putEditFolderUrl = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.principalLiteDocApiUrl
  ? `${configInfo.client.principalLiteDocApiUrl}apps/PJM05/folder`
  : '',
  );

export const getFilesUpdate = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.flowApiUrl
    ? `${configInfo.client.flowApiUrl}api/documents/upload`
    : '',
);
export const getFilesList = (documentId)=> createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.flowApiUrl
    ? `${configInfo.client.flowApiUrl}api/documents/${documentId}?pageSize=10&pageNumber=1&fetchAll=true&appCode=ELIS&selectedColumns=fileId,fileName,fileSize,fileUrl,originalFileName,mimeType`
    : '',
);
export const getFilesDownload = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.flowApiUrl
    ? `${configInfo.client.flowApiUrl}api/documents/download`
    : '',
);

export const getFilesDelete = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.flowApiUrl
    ? `${configInfo.client.flowApiUrl}api/documents`
    : '',
);

export const getDownloadHandoverUpdate = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.principalLiteDocApiUrl
    ? `${configInfo.client.principalLiteDocApiUrl}apps/PJM05/download/downloadHandoverPackage`
    : '',
);

export const deleteProjectDocument = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.principalLiteDocApiUrl
    ? `${configInfo.client.principalLiteDocApiUrl}apps/PJM05/action/`
    : '',
);