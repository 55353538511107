import React from 'react';
import { ReactComponent as FileSvg } from '../images/folder.svg';

type IProps = {
    height?: string | number;
    className?:string;
    content?: string;
}

const NoApprovals: React.FC<IProps> = ({ height, className }) => {
    return (
        <div className={`no-approvals d-flex justify-content-center align-items-center flex-column ${className}`} style={{ height: height ?? 'auto' }}>
            <FileSvg />
            <h5>No Approvals</h5>{' '}
            <h6>Requests that require your approval will appear here</h6>
        </div>
    );
};

export default NoApprovals;